import React, { useState } from "react";

import addToMailchimp from "gatsby-plugin-mailchimp";

export default function SubscribeModal(props) {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  if (
    isOpen === false ||
    (typeof window !== "undefined" &&
      window.sessionStorage &&
      sessionStorage.getItem("closedSubscribeModal") === "true") ||
    (typeof window !== "undefined" &&
      localStorage.getItem("subscribed") === "true" &&
      status !== "success")
  ) {
    return null;
  }

  if (status === "success") {
    return (
      <div className="subscribe-modal">
        <h3>Success!</h3>
        <p>
          Please check your email for a confirmation to start receiving shows
          and updates from me.
        </p>
        <div className="submit-button">
          <button onClick={() => setIsOpen(false)}>Close</button>
        </div>
      </div>
    );
  }

  return (
    <div className="subscribe-modal">
      <h3>
        {status === "error" ? "Error - please try again" : "Subscribe now!"}
      </h3>
      <button
        className="button-close"
        onClick={() => {
          typeof window !== undefined &&
            window.sessionStorage &&
            sessionStorage.setItem("closedSubscribeModal", "true");
          setIsOpen(false);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M18 6L6 18M6 6l12 12" />
        </svg>
      </button>
      <p>
        {" "}
        {status === "error"
          ? "If this continues, please send an email to heysean@seanburke.net"
          : "Get a link to all new shows right after they air."}
      </p>
      {status !== "error" && (
        <p style={{ color: "white", marginTop: "-0.5rem" }}>
          We will never sell or share your email address - ever.
        </p>
      )}
      <div>
        <input
          type="text"
          className="form-control"
          placeholder="Email address"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className="submit-button">
        <button
          onClick={() => {
            setStatus("subscribing");
            addToMailchimp(email, {}).then(data => {
              if (
                data.result === "success" ||
                data.msg.includes("is already subscribed")
              ) {
                typeof window !== "undefined" &&
                  localStorage.setItem("subscribed", "true");
                setStatus("success");
              } else {
                setStatus("error");
                setErrorMessage(data.msg);
              }
            });
          }}
          disabled={email === ""}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
